<template>
  <div>
    <title-nav :title="'공지'" :nav="'CS / 문자'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-form-group>
            <b-input-group prepend="LMS 전송 방식" class="w-100">
              <b-form-select v-model="mms_type" :options="options" />
              <template v-if="mms_type == 'parts'">
                <b-form-input
                  v-model="hpnos"
                  :state="isValidNumber"
                  placeholder="01012341234,01012341234..."
                  pattern="(\d{10,}(,)*)+"
                  aria-required="번호입력"
                  class="w-75"
                  required
                ></b-form-input>
              </template>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group prepend="전송 방식">
              <b-form-select v-model="isReserved" :options="isReservedOpt" />
              <template v-if="isReserved === 'true'">
                <b-form-datepicker id="datepicker-1" v-model="reservedDay" size="sm" locale="kr" />
                <b-form-timepicker id="timepicker-1" v-model="reservedTime" size="sm" locale="kr" />
              </template>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group prepend="제목" class="w-100">
              <b-form-input v-model="subject" placeholder="(광고)...." required />
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-input-group prepend="첨부" class="w-100">
              <b-form-input v-model="filePath" placeholder="/vod/...jpg" required />
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-form-textarea
              v-model="contents"
              id="contents"
              placeholder="내용을 입력하세요"
              rows="10"
              max-rows="10"
              required
              wrap="hard"
            />
            <p>
              <span>
                광고문자 표기법을 준수하려면 다음의 내용이 필수적으로 들어가야 합니다.<br />
              </span>
              - 문자 메시지 서두에 (광고)라는 문구를 삽입 <br />
              - 전송자 명칭과 연락처 명기 <br />
              - 문자 메시지 말미에 ‘무료거부 080-XXXX-XXXX’ 표시 <br />
              -현재는 [무료수신거부] <br />
              https://fmschool.co.kr/my/info<br />
            </p>
          </b-form-group>
        </div>
        <div>
          <b-button @click="onReset()" class="m-1" variant="">
            취소
          </b-button>
          <b-button @click="submitLMS()" class="m-1" variant="primary">
            전송
          </b-button>
        </div>
      </div>
    </b-container>
    <!-- 삭제 모달창 -->
  </div>
</template>

<script>
import { sendLMS } from '@/api/cs'
import Editor from '../util/Editor'
export default {
  name: 'NoticeList',
  components: { Editor },
  data() {
    return {
      options: [
        { value: 'all', text: '전체(마케팅 동의)' },
        { value: 'parts', text: '일부' }
      ],
      isReservedOpt: [
        { value: 'true', text: '예약 전송' },
        { value: 'false', text: '즉시 전송' }
      ],

      contents: null,
      subject: null,
      noti_type: null,
      isAll: true,
      mms_type: 'all',
      hpnos: null,
      isReserved: false,
      reservedDay: null,
      reservedTime: null,
      filePath: null
    }
  },
  computed: {
    isValidNumber() {
      const regex = new RegExp(/(\d{10,}(,)*)+/g)
      let isValid = regex.test(this.hpnos)
      return isValid
    }
  },
  methods: {
    onReset() {
      this.subject = null
      this.contents = null
      this.reservedDay = null
      this.reservedTime = null
      this.hpnos = null
      this.mms_type = 'all'
      this.filePath = null
    },
    async submitLMS() {
      let dt = this.isReserved ? `${this.reservedDay} ${this.reservedTime}` : null
      // 전체전송
      if (this.mms_type === 'all') {
        if (this.mms_type && this.contents && this.subject && this.filePath) {
          await sendLMS('', this.mms_type, this.contents, this.subject, dt, this.filePath)
          if (response.code != '0000') {
            console.log(`error: ${response.data.code}, msg:${response.data.message}`)
          } else {
            alert('전송 성공')
            this.onReset()
          }
        } else {
          alert('내용, 제목, 첨부 모두 입력해주세요')
        }
      } else {
        if (this.hpnos && this.mms_type && this.contents && this.subject) {
          const response = await sendLMS(
            this.hpnos,
            this.mms_type,
            this.contents,
            this.subject,
            dt,
            this.filePath
          )
          if (response.code != '0000') {
            console.log(`error: ${response.data.code}, msg:${response.data.message}`)
          } else {
            alert('전송 성공')
            this.onReset()
          }
        } else {
          alert('휴대폰 번호, 내용, 제목 모두 입력해주세요')

          // console.log(this.hpnos, 'all', this.contents, this.subject)
        }
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  padding-left: 10px;
}

table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: left;
  padding-left: 10px;
}
</style>
